
import React, { useState, useRef, useEffect, ReactElement, Component } from "react";
import { connect } from "react-redux";

function PublicRoute({ component: Component, authentReducer, ...rest }:any)
{
    useEffect(()=>{

    },
    []);
    
    return(
        <Component {...rest} />
    );
}

const mapStateToProps = (state: any) => {
    return {
      authentReducer: state.authentReducer
    };
  };

export default connect(mapStateToProps)(PublicRoute);

